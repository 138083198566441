interface IGeneralArg {
  carouselElement: Ref<HTMLElement | undefined>; // Родительский элемент карусели, который включает в себя элементы
  carouselLine: Ref<HTMLElement | undefined>; // Определяющая линия
  gapInDrop: number; // Отступы между элементами
  itemCount: number; // Кол-во элементов
  itemEndCount: number; // Кол-во элементов отображаемых с конца до определяющей линии
  itemSize: Ref<number>; // Высота карточки внутри карусли
}

export function useSpinnerAlgorithm({
  carouselElement,
  carouselLine,
  itemCount,
  itemEndCount,
  itemSize,
  gapInDrop,
}: IGeneralArg) {
  // Выполняет вычисления свзяанные с определяющей линией
  const calculatedValuesDefiningLine = (): number => {
    const { height: definingLineHeight, y: definingLineY } = GlobalUtils.DOM.getCoords(carouselLine.value!);
    // Сумма верхней границы и высоты определяющей линии
    return definingLineHeight + definingLineY;
  };

  // случайный сдвиг
  const topIndent = Math.round(GlobalUtils.Maths.randomIntFromInterval(2, itemSize.value - 2));

  const animation = (): string => {
    if (itemEndCount === -1) {
      return `translateY(-100%)`;
    }

    // Индекс элемента массива, куда будет осущесвтляться подстановка победного элемента
    const winningItemIndex = itemCount - itemEndCount;

    // Координаты родителя карточек рулетки
    const { y: parentBlockY } = GlobalUtils.DOM.getCoords(carouselElement.value!);

    const defineLineSum = calculatedValuesDefiningLine();

    // Сумма отступов между элементами до элемента под победным индексом ( отступ снизу от него не включён)
    const gapRangeToSelectedElement = gapInDrop * (winningItemIndex - 1);
    // Расстояние от верхнего края до стрелки, то есть сколько элементов влезает до стрелки сверху
    const rangeBetweenTopToLine = defineLineSum - parentBlockY;

    // Позволяет нам определить расстояние до элемента под нужным индексом в сумме с отступами
    const distanceToItem = itemSize.value * winningItemIndex + gapRangeToSelectedElement;

    // на случай рандомного положения карточки относительно линии
    const scrollLength = distanceToItem - rangeBetweenTopToLine - topIndent;

    // выставляем линию ровно посередине карты
    //  const scrollLength = distanceToItem - rangeBetweenTopToLine - itemSize.value / 2;

    return `translateY(-${scrollLength}px)`;
  };

  return { animation, topIndent };
}
