<template>
  <div class="running-battle-controller">
    <CaseCollectionController v-if="battle && battle.ulid" />
    <BattlesPageTitleWidget
      v-if="BattlesUtils.Validity.checkWhetherDataRangeValid(battleCoinsSummary)"
      class="running-battle-page__title"
      hide-tooltip
      show-battle-subtitle
      :show-rag="false"
      :coins-amount="GlobalUtils.Currency.formatDataRange([battleCoinsSummary[0], battleCoinsSummary[1]])"
    />
    <PlayerWindowsController />
    <BattlesPopupWrapper v-model:is-open="isFinishBattle">
      <LazyBattlesFinishBattlePopup
        :is-spectator="playersStore.isMeSpectator"
        :total-coins="coinsFromBattle"
        :players="playersStore.players"
        :total-sell="singleBattleStore.battle?.totalSell || 0"
        :winning="singleBattleStore.battle?.total || 0"
        :battle-price="singleBattleStore.battle?.price || 0"
        :title-text="$t('battles.runningBattle.battleEnded')"
        :middle-text="popupText"
        :is-win="playersStore.isMeWon"
        :emojies-getter="
          (memberId?: number) => battlesEmojiesStore.getEmojiesList(EmojiesListsKeys.FINISH_POPUP, memberId!)
        "
        :text-color="eventStore.coinColor"
        :show-rag="false"
        :is-sell-loading="isPopupSellLoading"
        :is-auth="authStore.isAuth"
        @on-close-click="isFinishBattle = false"
        @on-go-battles-click="() => navigateTo(localeRoute(ROUTING.BATTLES.MAIN))"
        @on-create-click="handleCreateTheSame"
        @on-sale-click="handleSell"
        @on-emodji-click="playersStore.sendDebouncedEmoji($event, EmojiesListsKeys.FINISH_POPUP)"
        @on-emodji-ends="battlesEmojiesStore.deleteEmoji($event, EmojiesListsKeys.FINISH_POPUP)"
      />
    </BattlesPopupWrapper>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import CaseCollectionController from '../CaseCollectionController/CaseCollectionController.vue';
import PlayerWindowsController from '../PlayerWindowsController/PlayerWindowsController.vue';
import { useSingleBattleStore } from '~/features/battles/store/singleBattle.store';
import { usePlayersStore } from '~/features/battles/store/players.store';
import { useBattlesListStore } from '~/features/battles/store/battlesList.store';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { BattlesPlayersAmount } from '~/features/battles/constants/rules';
import { useAlertStore } from '~/store/alert/alert.store';
import type { IBattlesBattleShortItemInStore } from '~/features/battles/types/battlesStore.types';
import { useUserStore } from '~/store/user/user.store';
import { EmojiesListsKeys } from '~/features/battles/constants/emojies';
import { useBattlesEmojiesStore } from '~/features/battles/store/battlesEmojies.store';
import BattlesUtils from '~/features/battles/utils';
import { useEventStore } from '~/store/event/event.store';
import { BattleEvents } from '~/repository/amplitude/events/battle';
import type { IBattleCompletedEvent } from '~/repository/amplitude/types/battle.types';

const localeRoute = useLocaleRoute();

const eventStore = useEventStore();

const singleBattleStore = useSingleBattleStore();
const playersStore = usePlayersStore();
const userStore = useUserStore();
const authStore = useAuthStore();
const alertStore = useAlertStore();
const battlesEmojiesStore = useBattlesEmojiesStore();

const { battle, battleCases, battleCoinsSummary } = storeToRefs(singleBattleStore);

const { t } = useI18n();

const isFinishBattle = ref<boolean>(false);
const coinsFromBattle = ref(0);
const isPopupSellLoading = ref(false);

const popupText = computed(() => {
  return playersStore.isMeWon ? t('battles.runningBattle.won') : t('battles.runningBattle.lost');
});

const handleSell = async () => {
  isPopupSellLoading.value = true;

  try {
    await singleBattleStore.sellWinnings();

    if (battle.value && battle.value?.totalSell && typeof battle.value?.totalSell === 'number') {
      /* Если решили продать скины, то уменьшаем баланс скинов */
      userStore.decreaseBalanceOn(Number(battle.value.totalSell || 0), 'totalAmountItems');
      /* И сразу же увеличиваем баланс на проданную сумму */
      userStore.increaseBalanceOn(Number(battle.value.totalSell || 0), 'balance');
    }

    alertStore.show({
      message: t('alerts.itemsInSaleQueue'),
      title: '',
      type: 'success',
    });
  } catch (e) {
    alertStore.showError({
      message: e as string,
      title: t('alerts.error.sellItems'),
    });
  } finally {
    isPopupSellLoading.value = false;
  }
};

singleBattleStore.callbacks.afterBattleEnd.push(() => {
  if (!userStore.userId || !battle.value) return;

  /* Меняем сумму коинов на конец батла */
  userStore.increaseBalanceOn(playersStore.battleCoinsCalculate() || 0, 'coinBalance');

  if (battle.value && battle.value?.totalSell && typeof battle.value?.totalSell === 'number') {
    /* Меняем сумму скинов на конец батла (пока их не продали плюсуем весь выигрыш к сумме скинов) */
    if (playersStore.isMeWon) userStore.increaseBalanceOn(Number(battle.value?.totalSell || 0), 'totalAmountItems');
  }
});

const handleCreateTheSame = () => {
  useBattlesListStore().createTheSameBattle(
    {
      bots: !!battle?.value?.bots,
      cases: [...battleCases.value].reverse(),
      private: !!battle?.value?.private,
      slots: battle?.value?.slots ?? BattlesPlayersAmount.min,
    } as IBattlesBattleShortItemInStore,
    'battles/main',
  );
  isFinishBattle.value = false;
};

singleBattleStore.callbacks.afterBattleEnd.push(() => {
  coinsFromBattle.value = playersStore.battleCoinsCalculate();
  setTimeout(() => {
    isFinishBattle.value = true;

    if (!battle.value) return;
    const botsCount = playersStore.players.filter((player) => player.info?.bot).length;

    const data: IBattleCompletedEvent = {
      'Battle Price': singleBattleStore.battlePriceSummary,
      'Battle Size': `${battle.value.slots}`,
      'Bot Count': `${botsCount}`,
      'Is Host': playersStore.isMeHost ? 'yes' : 'no',
      'Is Private': battle.value.private ? 'yes' : 'no',
      'Is Won': playersStore.isMeWon ? 'yes' : 'no',
      'Players Count': `${battle.value.slots - botsCount}`,
      'Rounds': battle.value.rounds.length,
    };
    if (playersStore.me && playersStore.isMeWon) {
      data['Is Profit'] = playersStore.me.total > singleBattleStore.battlePriceSummary ? 'yes' : 'no';
    }
    BattleEvents.battleCompleted(data);
  }, 2000);
});
</script>

<style src="./RunningBattleController.scss" scoped lang="scss" />
