import { Colors } from '~/constants/colors.constants';
import { BattlesColors } from '~/features/battles/constants/colors';

export const noPlayersColors = {
  contentBorder: Colors.NEUTRAL.GREY_LIGHT,
  contentShadow: BattlesColors.WAIT,
  mainBackground: BattlesColors.WAIT,
  mainPulse: BattlesColors.WAIT,
};

export const waitingPlayColors = {
  contentBorder: BattlesColors.WIN,
  contentShadow: BattlesColors.WIN_30,
  mainBackground: BattlesColors.WIN_15,
  mainPulse: BattlesColors.WIN_30,
};

export const readyWithPlayersColors = {
  contentBorder: BattlesColors.READY_WITH_PLAYERS,
  contentShadow: BattlesColors.READY_WITH_PLAYERS_30,
  mainBackground: BattlesColors.READY_WITH_PLAYERS_15,
  mainPulse: BattlesColors.READY_WITH_PLAYERS_30,
};

export const readyWithBotsColors = {
  contentBorder: BattlesColors.READY_WITH_BOTS,
  contentShadow: BattlesColors.READY_WITH_BOTS_30,
  mainBackground: BattlesColors.READY_WITH_BOTS_15,
  mainPulse: BattlesColors.READY_WITH_BOTS_30,
};
