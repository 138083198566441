<template>
  <section class="created-status">
    <SharedRadialGradient :colors="colorsOptions" is-animating>
      <template #content>
        <SvgoPlayerCheckIcon v-if="isReady" />
        <SvgoPlayerGuyIcon v-else />
      </template>
    </SharedRadialGradient>
  </section>
</template>

<script setup lang="ts">
import type { ICreatedStatusProps } from './CreatedStatus.types';

import {
  noPlayersColors,
  readyWithBotsColors,
  readyWithPlayersColors,
  waitingPlayColors,
} from './CreatedStatus.presets';

const props = defineProps<ICreatedStatusProps>();

const { ready } = toRefs(props);

const colorsOptions = computed(() => {
  if (!props.isReady) {
    return noPlayersColors;
  }
  if (ready.value === 0) {
    return waitingPlayColors;
  }
  if (ready.value === 1) {
    return readyWithPlayersColors;
  }
  if (ready.value === 2) {
    return readyWithBotsColors;
  }

  return undefined;
});
</script>

<style scoped lang="scss" src="./CreatedStatus.scss" />
