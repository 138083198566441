export const enum EmojiesListsKeys {
  BATTLE_WINDOW = 'BATTLE_WINDOW',
  FINISH_POPUP = 'FINISH_POPUP',
}

export class EmojiItem {
  name: string;
  key: string;

  constructor(emojiName: string) {
    this.name = emojiName;
    this.key = emojiName;
  }
}

export const BattlesEmojiList = [
  new EmojiItem('SvgoEmodjiCryingBaby'),
  new EmojiItem('SvgoEmodjiFrogSweats'),
  new EmojiItem('SvgoEmodjiGrandpaLaugh'),
  new EmojiItem('SvgoEmodjiManYawns'),
  new EmojiItem('SvgoEmodjiPressF'),
  new EmojiItem('SvgoEmodjiRedBlockSmile'),
  new EmojiItem('SvgoEmodjiRoflanFace'),
];
