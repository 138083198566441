<template>
  <div class="shared-clipboard-text" :style="clipboardStyle">
    <div class="shared-clipboard-text__input" @click="copy">
      <div class="shared-clipboard-text__icon">
        <slot name="prepend"></slot>
      </div>
      <SharedText v-bind="props.linkTextStyle || {}">
        {{ props.linkRenderFn ? props.linkRenderFn(props.text.text) : props.text.text }}
      </SharedText>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CSSProperties } from 'vue';
import { computed } from 'vue';
import type { ISharedClipboardTextProps } from './SharedClipboardText.types';

const { proceedCssValue } = GlobalUtils.CSS;

const props = withDefaults(defineProps<ISharedClipboardTextProps>(), {
  bgColor: 'var(--transparent)',
});

const clipboardStyle = computed(() => {
  const styles: CSSProperties = {};
  if (props.borderRadius) styles.borderRadius = proceedCssValue(props.borderRadius);
  if (props.paddingY) {
    styles.paddingTop = proceedCssValue(props.paddingY, 'px');
    styles.paddingBottom = proceedCssValue(props.paddingY, 'px');
  }
  if (props.paddingX) {
    styles.paddingLeft = proceedCssValue(props.paddingX, 'px');
    styles.paddingRight = proceedCssValue(props.paddingX, 'px');
  }
  if (props.bgColor) {
    Object.assign(styles, GlobalUtils.CSS.parseColor(props.bgColor));
  }
  return styles;
});

const transformDataToString = (): string => {
  let result = '';
  for (const key in props.text) {
    result += `${props.text[key]} `;
  }

  return result.trim();
};

const copy = () => navigator.clipboard.writeText(transformDataToString());
</script>

<style src="./SharedClipboardText.scss" lang="scss" scoped></style>
