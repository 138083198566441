<template>
  <div class="share_container">
    <div class="share_container__counters">
      <BattlesValueInfoWidget :name="$t('battles.rounds')" :value="rounds.all" :animate="false" />
      <BattlesValueInfoWidget
        :name="$t('battles.players')"
        :value="players.current ?? 0"
        :value-render-fn="playersCounterRenderFn"
        :animate="false"
      />
      <BattlesValueInfoWidget
        :name="$t('battles.price')"
        :value="sum"
        :value-render-fn="(value: number | string) => GlobalUtils.Currency.formatCurrency(value)"
        :animate="false"
        :value-text-props="{
          color: Colors.MAIN.GOLD,
        }"
      />
    </div>
    <div class="share_container__link">
      <SharedClipboardText
        :text="{ text: link }"
        :link-render-fn="(linkValue: string) => shortLinkValue(linkValue)"
        :link-text-style="clipboardTextOptions"
        :class="props.isFinal && 'share_container__link__copy'"
        @click="handleClipboardClick"
      >
        <template #prepend>
          <SvgoAlertSuccessIcon v-if="isClipboardClicked" :width="16" :height="16" />
          <SvgoLinkIcon v-else :width="svgSize" :height="svgSize" />
        </template>
      </SharedClipboardText>
      <Transition name="link-viewers">
        <SharedText v-if="!props.isFinal" v-bind="viewsOptions">
          <template #prepend>
            <div class="share_container__link__viewers">
              <SvgoEyeIcon :width="svgSize" :height="svgSize" class="svg-eye" />
              {{ viewers }}
            </div>
          </template>
        </SharedText>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IPlayersShareWidgetProps } from './PlayersShareWidget.types';
import BattlesUtils from '~/features/battles/utils';
import { Colors } from '~/constants/colors.constants';
import { FontSizesNumber, FontWeights } from '~/types/SharedFont.types';
import type { TPossibleNull } from '~/types/Shared.types';

const props = withDefaults(defineProps<IPlayersShareWidgetProps>(), {
  players: () => {
    return { all: 0 };
  },
  rounds: () => {
    return { all: 0 };
  },
  sum: 0,
  viewers: 0,
});

const clipboardTimeout = ref<TPossibleNull<ReturnType<typeof setTimeout>>>(null);
const isClipboardClicked = ref(false);

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const playersCounterRenderFn = (value: number | string) => {
  return `${value ?? 0}/${props.players.all}`;
};

const clipboardTextOptions = computed(() => ({
  color: Colors.NEUTRAL.WHITE,
  maxWidth: changeXL(146, 84),
  overflow: 'hidden',
  size: changeXL(FontSizesNumber.MEDIUM, FontSizesNumber.SMALL_XS),
  weight: FontWeights.BOLD,
}));

const viewsOptions = computed(() => ({
  color: Colors.NEUTRAL.WHITE_65,
  size: changeXL(FontSizesNumber.MEDIUM, FontSizesNumber.SMALL_XS),
  weight: FontWeights.BOLD,
}));

const svgSize = computed(() => changeXL(24, 16));

// Эта функция укорачивает ссылку батла для короткой версии в интерфейсе. После linkValue первое число означает slice начала шортссылки, вторая цифра - конец слайса ссылки
const shortLinkValue = (linkValue: string) => {
  return BattlesUtils.Format.createShortUrl(linkValue, changeXL(12, 10), changeXL(7, 5)).short;
};
const handleClipboardClick = () => {
  if (clipboardTimeout.value) {
    clearTimeout(clipboardTimeout.value);
  }
  isClipboardClicked.value = true;

  clipboardTimeout.value = setTimeout(() => {
    isClipboardClicked.value = false;
  }, 1001);
};
</script>

<style src="./PlayersShareWidget.scss" scoped lang="scss"></style>
