<template>
  <div :class="['player-reward__line', isShowReward]"></div>
</template>

<script setup lang="ts">
import type { IPropsRewardLine } from './RewardLine.types';
const props = defineProps<IPropsRewardLine>();

const isShowReward = computed(() => {
  return props.show && props.showEndRoundLine ? 'showend' : 'show';
});

const lineWidth = computed(() => {
  return props.width + '%';
});
</script>

<style lang="scss" scoped>
.show,
.showend {
  --width-reward: v-bind(lineWidth);
}

.player-reward__line {
  --color-reward: v-bind(props.color);
}
</style>

<style src="./RewardLine.scss" lang="scss" scoped></style>
