<template>
  <section class="multicast-animation">
    <div class="step-container">
      <MulticastCircle v-if="showCircle" :multi-count="props.drops.length" @start-circle-show="emits('start-circle')" />
    </div>
    <div class="step-container">
      <MulticastFan v-if="showDrop" :drops="props.drops" @start-fan="emits('start-fan-show')" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { BattlesMulticastTimeouts } from '../../constants/rules';
import MulticastCircle from './MulticastCircle/MulticastCircle.vue';
import MulticastFan from './MulticastFan/MulticastFan.vue';
import type { IMulticastAnimationProps, IMultiAnimationEmits } from './MulticastAnimation.types';

const props = defineProps<IMulticastAnimationProps>();

const emits = defineEmits<IMultiAnimationEmits>();

let circleTimeout: ReturnType<typeof setTimeout>;
let dropTimeout: ReturnType<typeof setTimeout>;

const CircleOn = () => {
  showCircle.value = true;
  circleTimeout = setTimeout(() => {
    showCircle.value = false;
  }, BattlesMulticastTimeouts.fanTime);

  dropTimeout = setTimeout(() => {
    showDrop.value = true;
  }, BattlesMulticastTimeouts.circleTime);
};

const circleOff = () => {
  clearTimeout(circleTimeout);
  clearTimeout(dropTimeout);
};

const showCircle = ref(false);
const showDrop = ref(false);

onMounted(() => {
  CircleOn();
});

onUnmounted(() => {
  circleOff();
});
</script>

<style src="./MulticastAnimation.scss" scoped lang="scss"></style>
