<template>
  <div class="not-found-battle-page">
    <div class="not-found-battle-page__content">
      <div class="content__image">
        <img :src="NoFoundBattleImage" alt="" class="content__image_img" />
      </div>
      <SharedText
        :gradient="{
          colorStops: BattlesColors.MAIN_LIGHT,
          toDirection: GlobalUtils.Converting.toDegs(0),
        }"
        :letter-spacing="GlobalUtils.Converting.toPx(changeXl(3.2, 2))"
        :size="changeXl(FontSizes.LARGE_3XL, FontSizes.LARGE_XL)"
        :weight="FontWeights.BOLD"
        as="h1"
        font-case="upper"
        font-family="Reaver"
      >
        {{ $t('alerts.battleWasNotFound') }}
      </SharedText>
      <SharedText
        :color="Colors.NEUTRAL.WHITE"
        :size="changeXl(FontSizes.LARGE, FontSizes.SMALL)"
        :weight="FontWeights.REGULAR"
        align="center"
        as="h4"
      >
        {{ $t('battles.battleNotFoundInfo1') }}
        <br /><br />
        {{ $t('battles.battleNotFoundInfo2') }}
      </SharedText>
      <div class="not-found-button">
        <SharedKitButton
          :color-preset="BattleColorPresets.TRANSPARENT_WHITE"
          :size-preset="actionButtonsSize"
          :type-preset="TypePresets.RECTANGLE"
          :text="$t('battles.battlesList')"
          :link-props="{
            to: ROUTING.BATTLES.MAIN.path,
          }"
        >
          <template #prepend>
            <SvgoArrowLeftIcon :width="backArrowSize" :height="backArrowSize" />
          </template>
        </SharedKitButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import { BattlesColors } from '~/features/battles/constants/colors';
import { Colors } from '~/constants/colors.constants';
import { useSingleBattleStore } from '~/features/battles/store/singleBattle.store';
import NoFoundBattleImage from '~/assets/images/Battles/no-battle.png';
import { BattleColorPresets } from '~/features/battles/presets/SharedKitButton.presets';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';

const changeXl = GlobalUtils.Media.changeByMedia('xl');

const localeRoute = useLocaleRoute();

definePageMeta({
  layout: 'battles',
});

const singleBattleStore = useSingleBattleStore();
const { hasFetchError } = storeToRefs(singleBattleStore);
const changeXL = GlobalUtils.Media.changeByMedia('xl');

const backArrowSize = computed(() => changeXL(24, 20));

const actionButtonsSize = computed(() => changeXL(SizePresets.L, SizePresets.M));

if (!hasFetchError) {
  await navigateTo(localeRoute(ROUTING.BATTLES.MAIN));
}
</script>

<style lang="scss" scoped src="./NotFoundBattlePage.scss" />
