<template>
  <div class="value-info-widget">
    <SharedText
      :size="changeXl(FontSizes.MEDIUM, FontSizes.SMALL_XS)"
      :weight="FontWeights.BOLD"
      font-case="upper"
      class="value-info-widget__title"
      v-bind="props.nameTextProps"
    >
      {{ props.name }}
    </SharedText>
    <SharedText
      :size="changeXl(FontSizes.LARGE_XL, FontSizes.MEDIUM)"
      :weight="FontWeights.BLACK"
      class="value-info-widget__value"
      v-bind="props.valueTextProps"
    >
      {{ resultValue }}
    </SharedText>
  </div>
</template>

<script setup lang="ts">
import type { IPropsValueInfoWidget } from '~/features/battles/widgets/ValueInfoWidget/ValueInfoWidget.types';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import BattlesUtils from '~/features/battles/utils';

const changeXl = GlobalUtils.Media.changeByMedia('xl');

const props = withDefaults(defineProps<IPropsValueInfoWidget>(), {
  animate: true,
  animation: () => ({}),
  nameTextProps: () => ({}),
  valueTextProps: () => ({}),
});

const animatedValue = ref<number>(0);

/* Флаг, чтобы не анимировать значения, когда мы перезаходим на страницу */
let isMounted = false;

const resultValue = computed(() => {
  const toFixed = GlobalUtils.Numbers.toFixed;
  let value: string | number = toFixed(Number(props.value), 0);
  /* Если анимируем, то меняет значение на анимации */
  if (props.animate && isMounted) {
    value = toFixed(Number(animatedValue.value), 0);
  }
  /* Если нужно его преобразовать, то после анимации преобразуем */
  if (props.valueRenderFn) {
    value = props.valueRenderFn(value) as string;
  }
  return value;
});

const numberAnimationConfig = BattlesUtils.Animations.createNumberValueConfig(animatedValue);

function animateValueIfAllowed() {
  props.animate && useAnime(numberAnimationConfig(+props.value));
}

onMounted(() => {
  if (!isMounted) {
    isMounted = true;
    return;
  }
  animateValueIfAllowed();
});
onUpdated(() => {
  if (!isMounted) return;
  animateValueIfAllowed();
});
</script>

<style src="./ValueInfoWidget.scss" scoped lang="scss" />
