<template>
  <section :style="style" :class="classes">
    <div class="blur-round" />
    <div class="outer-round">
      <div class="inner-round">
        <div class="inner-round__icon">
          <slot />
        </div>
      </div>
    </div>
    <RewardLine
      :width="props.roundPrizesPercentOfTotal"
      :color="props.prizeLineColor"
      :show="props.prizeLineShow"
      :show-end-round-line="props.isLastRound"
    />
  </section>
</template>

<script setup lang="ts">
import RewardLine from '../RewardLine/RewardLine.vue';
import type { IPropsRoundResultStatus } from './RoundResultStatus.types';

// если true, то круги увеличиваются и останавливаются, иначе - уменьшаются
const isGrowRoundSize = ref(true);

const props = withDefaults(defineProps<Partial<IPropsRoundResultStatus>>(), {
  innerColor: 'rgba(139 140 150 / 15%)',
  outerColor: 'rgba(139 140 150 / 7%)',
  timeForChange: 1,
  timeStable: 1,
});

const timer = setTimeout(() => (isGrowRoundSize.value = false), props.timeForChange + props.timeStable);
const style = computed(() => ({
  '--animation-time': `${props.timeForChange}ms`,
  '--inner-color': `${props.innerColor}`,
  '--outer-color': `${props.outerColor}`,
}));

const classes = computed(() => ({
  'end-battle-status': isGrowRoundSize.value,
  'end-battle-status__less': !isGrowRoundSize.value,
}));

onUnmounted(() => {
  clearTimeout(timer);
});
</script>

<style src="./RoundResultStatus.scss" lang="scss" scoped></style>
