<template>
  <BattlesCaseBattleCarousel
    v-if="battle"
    :is-auth="authStore.isAuth"
    :cases="battleCases"
    :players="{ all: battle.slots, current: battle.members.length }"
    :rounds="{ all: battle.rounds.length, current: battleAnimator.round }"
    :sum="battle.price"
    :viewers="battle.spectators"
    :is-active-round="battleState.started"
    :is-done-round="battleState.finished"
    :link="href"
    @on-create-the-same="handleOnCreateTheSameBattle"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSingleBattleStore } from '~/features/battles/store/singleBattle.store';
import { useBattlesListStore } from '~/features/battles/store/battlesList.store';
import type { IBattlesBattleShortItemInStore } from '~/features/battles/types/battlesStore.types';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useAlertStore } from '~/store/alert/alert.store';
import { useBattlesDataStateStore } from '~/features/battles/store/dataState.store';

const { xl } = useMedia();

const { t } = useI18n();

const authStore = useAuthStore();
const alertStore = useAlertStore();
const dataStateStore = useBattlesDataStateStore();
const singleBattleStore = useSingleBattleStore();
const router = useRouter();
const href = ref<string>('');

const { battle, battleAnimator, battleCases, battleState } = storeToRefs(singleBattleStore);

const handleOnCreateTheSameBattle = () => {
  if (!authStore.isAuth) {
    /* Desktop */
    if (xl.value) return dataStateStore.changeDataState('isAuthNeeded', true);

    /* Mobile & Tablet */
    return alertStore.show({
      title: t('alerts.errorBattleAuthorize'),
      type: 'error',
    });
  }

  if (router.currentRoute.value.name) {
    useBattlesListStore().createTheSameBattle(
      {
        bots: battle.value!.bots,
        cases: [...battleCases.value].reverse(),
        private: battle.value!.private,
        slots: battle.value!.slots,
      } as IBattlesBattleShortItemInStore,
      router.currentRoute.value.name.toString(),
    );
  }
};

onMounted(() => {
  if (document) {
    href.value = document.location.href;
  }
});
</script>

<style src="./CaseCollectionController.scss" scoped lang="scss" />
