<template>
  <component :is="props.as" class="emodji-animator">
    <slot />
    <div class="emodji-container">
      <TransitionGroup name="emodji" :css="false" @enter="onEnter" @after-enter="afterEnter">
        <!-- @vue-skip -->
        <div
          v-for="emodjiItem in props.emojies"
          :key="emodjiItem.id"
          :data-id="emodjiItem.id"
          class="emodji-container__item"
        >
          <Component :is="emodjiItem.content" />
        </div>
      </TransitionGroup>
    </div>
  </component>
</template>

<script setup lang="ts">
import type { Property } from 'csstype';
import type { IBattlesEmodjiInStore } from '~/features/battles/types/battlesStore.types';

const props = defineProps<{
  as: string;
  emojies: IBattlesEmodjiInStore[];
  startCords: {
    x: Property.Right | number;
    y: Property.Bottom | number;
  };
}>();

const emits = defineEmits<{
  (event: 'onEmojiEnds', value: string): void;
}>();

const coords = computed(() => {
  const result: {
    x: number;
    y: number;
  } = { x: 0, y: 0 };

  for (const cordKey in props.startCords) {
    result[cordKey as keyof typeof result] = GlobalUtils.CSS.proceedCssValue(
      props.startCords[cordKey as keyof typeof props.startCords],
    );
  }

  return result;
});

function onEnter(el: Element, done: () => void) {
  el.addEventListener('animationend', () => done());
}

function afterEnter(el: Element) {
  const dataset = ((el as HTMLElement).dataset || {}) as { id?: string };
  if (dataset.id) emits('onEmojiEnds', dataset.id);
}
</script>

<style scoped lang="scss">
.emodji-animator {
  --x: v-bind('coords.x');
  --y: v-bind('coords.y');

  position: relative;
}

.emodji-container {
  position: absolute;
  right: var(--x);
  bottom: var(--y);
  z-index: var(--ui-layer-1);

  &__item {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 18px;
    height: 18px;
    opacity: 1;
    transform: translate(-50%, 50%);
    animation: emodji 2s linear;
    animation-iteration-count: 1;

    :deep(svg) {
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes emodji {
  0% {
    bottom: 0;
    left: 0;
  }

  10% {
    bottom: 8px;
    left: -4px;
  }

  20% {
    bottom: 20px;
    left: -7px;
  }

  40% {
    bottom: 45px;
    left: 0;
  }

  60% {
    bottom: 80px;
    left: 10px;
  }

  80% {
    bottom: 120px;
    left: 5px;
  }

  90% {
    opacity: 1;
  }

  100% {
    bottom: 150px;
    left: 0;
    opacity: 0;
    transform: scale(3.2);
  }
}
</style>
