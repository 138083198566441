<template>
  <section class="end-status">
    <Transition name="status-texts">
      <div v-if="isWon" class="end-status__texts">
        <div class="end-status__texts-icons">
          <SvgoCrownGreen :width="changeXL(30, 18)" :height="changeXL(30, 18)" />
          <SvgoDiceGreen v-if="isDraw" />
        </div>
        <SharedText v-if="isDraw" class="end-status__texts-subtitle" v-bind="subtitleStyles">
          {{ $t('battles.playerWindow.definedRandomly') }}
        </SharedText>
      </div>
      <div v-else-if="isLost" class="end-status__texts">
        <div class="end-status__texts-icons">
          <SvgoDiceRed v-if="isLost && isDraw" />
          <SvgoHelmRed v-if="isLost" :width="changeXL(24, 14)" :height="changeXL(32, 19)" />
        </div>
      </div>
    </Transition>
    <div class="end-status__main">
      <Transition name="main-icon">
        <div v-if="isWon" class="end-status__main-icon">
          <SvgoPlayerWinBackgroundIcon :width="changeXL(188, 116)" :height="changeXL(188, 116)" />
        </div>
        <div v-else-if="isLost" class="end-status__main-icon">
          <SvgoPlayerLooseBackgroundIcon :width="changeXL(188, 116)" :height="changeXL(188, 116)" />
        </div>
      </Transition>
    </div>
    <Transition name="status-total">
      <div v-if="isWon" class="end-status__total">
        <SharedText
          class="end-status__total-text"
          v-bind="{
            color: Colors.NEUTRAL.WHITE,
            size: changeXL(FontSizesNumber.MEDIUM, FontSizesNumber.SMALL_XS),
            weight: FontWeights.BOLD,
            align: 'center',
          }"
          >{{ $t('battles.playerWindow.totalDrop') }}</SharedText
        >
        <SharedText
          class="end-status__total-sum"
          v-bind="{
            color: Colors.MAIN.GOLD,
            size: changeXL(FontSizesNumber.LARGE_XL, FontSizesNumber.LARGE),
            weight: FontWeights.BOLD,
          }"
          >{{ totalFormatted }}</SharedText
        >
      </div>
    </Transition>
  </section>
</template>

<script setup lang="ts">
import type { IEndStatusProps } from './EndStatus.types';
import { Colors } from '~/constants/colors.constants';
import { FontSizesNumber, FontWeights } from '~/types/SharedFont.types';
import { BattlesColors } from '~/features/battles/constants/colors';

const props = defineProps<IEndStatusProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const stylesVariables = computed(() => ({
  backdropColor: props.isWon ? BattlesColors.WIN : BattlesColors.LOSE,
  backgroundColor: props.isWon ? BattlesColors.WIN_30 : BattlesColors.LOSE_30,
}));

const subtitleStyles = computed(() => ({
  color: BattlesColors.WIN,
  size: changeXL(FontSizesNumber.MEDIUM, FontSizesNumber.SMALL_2XS),
  weight: FontWeights.BOLD,
}));

const totalFormatted = computed(() => GlobalUtils.Currency.formatCurrency(props.total));
</script>

<style lang="scss" scoped>
.end-status {
  --backdrop-color: v-bind('stylesVariables.backdropColor');
  --background-color: v-bind('stylesVariables.backgroundColor');
}
</style>

<style lang="scss" scoped src="./EndStatus.scss" />
